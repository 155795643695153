import Cookies from "js-cookie";
import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from "axios";
import { ThemeProvider } from "styled-components";
import { closableNotification } from "./components/elements/notification/ClosableNotification";
import { generateJWSToken } from "./utils/mercureAuth";
import ucFirst from "./utils/ucFirst";

import AdminWrapper from "./wrappers/AdminWrapper";
import UserWrapper from "./wrappers/UserWrapper";
import userRoutesConcat from "./routes/userRoutes";
import routes from "./routes/routes";
import adminRoutes from "./routes/adminRoutes";

import getUserInfo from "./utils/getUserInfo";
import userAuthenticationConfig from "./utils/userAuthenticationConfig";
import { checkCookiesAndSet } from "./utils/cookies.utils";
import { useDarkMode } from "./utils/useDarkMode";
import eventBus from "./utils/eventBus";
import { ONLINE_ACTIVITY_TIME, responseStatus, roles, siteSound, themeColor, TOPICS_LIST } from "./utils/consts";
import { darkTheme, lightTheme } from "./components/styles/theme/theme";

import { useCookies } from "react-cookie";
import useLocationBlocker from "./utils/locationBlocker";

import { GlobalStyle } from "./components/styles/globalStyle";
import "./assets/fonts/segoe_ui/style.css";
import "./assets/fonts/cryptomc-icons/style.css";
import "./assets/css/rc-select.css";
import "rc-dialog/assets/index.css";
import "rc-notification/assets/index.css";
import "rc-dropdown/assets/index.css";
import "rc-checkbox/assets/index.css";
import "rc-switch/assets/index.css";
import "rc-pagination/assets/index.css";
import "rc-tabs/assets/index.css";
import "rc-drawer/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import i18n from "i18next";
import { generatePath, lang } from "./utils/getLanguage";
import { listLanguage } from "./utils/listLanguage";
import { useBetween } from "use-between";
import LanguageStates from "./components/elements/listLanguage/LanguageStates";
import http from "./http";
import { useTranslation } from "react-i18next";
import { AliveScope } from "react-activation";

export const AppContext = createContext({});
export const MercureTopicOrigin = "http://localhost";
export const MercureUrl = new URL(`${window.location.origin}/.well-known/mercure`);

function App() {
  const { t } = useTranslation("siteOptions");

  const history = useHistory();
  // console.log('history:', history);

  const trustedCookies = useMemo(() => ["ref"], []);
  const [cookies, setCookie] = useCookies(trustedCookies);

  const [theme, themeToggler, mountedComponent] = useDarkMode();
  const [sound, setSound] = useState(siteSound.ON);
  // const [visible, setVisible] = useState(false);
  const [authenticated, setAuthenticated] = useState(!!getUserInfo());
  const [currentGame, setCurrentGame] = useState(null);

  const isValidToken = useCallback(() => {
    http.post("/api/is-valid-token", {}, userAuthenticationConfig(true)).catch(() => {
      localStorage.removeItem("token");
      setAuthenticated(false);
      // history.push("/");
    });
  }, []);

  const path = useLocation();
  const { setActiveLang, activeLang } = useBetween(LanguageStates);

  const setOffline = async () => {
    let user = getUserInfo();

    if (!user) {
      return;
    }

    await axios.post("/api/update-online", { isOnline: false },
      userAuthenticationConfig()).then(response => {
    }).catch(error => {
      closableNotification(error.response.data.message, "error");
    });
  };

  const setOnline = () => {
    let user = getUserInfo();

    if (!user) {
      return;
    }

    axios.post("/api/update-online", { isOnline: true }, userAuthenticationConfig(false));
  };

  useIdleTimer({
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange"
    ],
    crossTab: true,
    timeout: ONLINE_ACTIVITY_TIME,
    syncTimers: 200,
    throttle: 1000 * 3,
    onIdle: setOffline,
    onActive: setOnline
  });

  const handleOnIdle = useCallback(() => {
    eventBus.on("logout", (data) => {
      setOffline().finally(() => {
        localStorage.removeItem("clientId");
        localStorage.removeItem("token");
        localStorage.removeItem("paymentMethod");
        setAuthenticated(false);
        history.push("/");
      });
    });
  }, [history]);

  useLocationBlocker();

  useEffect(() => {
    checkCookiesAndSet(trustedCookies, setCookie, history);
    if (localStorage.getItem("token") !== null) {
      isValidToken();
    }
    if (i18n.language !== lang()) {
      i18n.changeLanguage(lang());
      setActiveLang(lang());
    }
    if (lang() === undefined) {
      document.cookie = `lang=${listLanguage.defaultLang}; path=/; max-age=-1`;
      document.cookie = `lang=${listLanguage.defaultLang}; path=/`;
    }
    // if (localStorage.getItem("gamePage") && path.pathname === "/") {
    //   history.replace(localStorage.getItem("gamePage"));
    // } else {
    //   history.push(generatePath(path.pathname, true));
    // }
    setActiveLang(lang());
    handleOnIdle();
  }, [
    handleOnIdle,
    history,
    isValidToken,
    path.pathname,
    setActiveLang,
    setCookie,
    trustedCookies,
  ]);
  const userInfo = useMemo(() => authenticated ? getUserInfo() : null, [authenticated]);

  const authRouteRender = useMemo(() => {
    if (authenticated && userInfo.roles.includes(roles.ADMIN)) {
      return (
        <AdminWrapper routes={adminRoutes}/>
      );
    } else if (authenticated && userInfo.roles.includes(roles.CLIENT)) {
      return (
        <UserWrapper routes={userRoutesConcat}/>
      );
    } else {
      return (
        <UserWrapper routes={routes}/>
      );
    }
  }, [authenticated, userInfo]);

  const themeMode = theme === themeColor.LIGHT ? lightTheme : darkTheme;

  const topic = TOPICS_LIST.ONLINE.CHECK_ONLINE + userInfo?.userId;
  const token = generateJWSToken(topic);

  useEffect(() => {
    const handleOffline = () => {
      closableNotification(t("offline"), "error");
    };
    window.addEventListener("offline", handleOffline);

    if (!userInfo) {
      return;
    }

    MercureUrl.searchParams.delete("topic");

    MercureUrl.searchParams.append("topic", topic);

    Cookies.set("mercureAuthorization", token, { path: "" });

    const es = new EventSource(MercureUrl, { withCredentials: true });

    es.addEventListener("message", setOnline);

    document.body.addEventListener("wheel", e => e.ctrlKey && e.preventDefault(), { passive: false });
    window.addEventListener("resize", e => e.preventDefault(), false);

    return () => {
      es.close();
      window.removeEventListener("offline", handleOffline);
      document.body.removeEventListener("wheel", e => e.ctrlKey && e.preventDefault());
      window.removeEventListener("resize", e => e.preventDefault(), false);
    };
  }, []);

  useEffect(() => {

    let user = getUserInfo();

    if (!user || !currentGame) {
      return;
    }

    let data = {
      field: "game",
      value: ucFirst(currentGame)
    };

    axios.post("/api/update-online-data", data, userAuthenticationConfig(false)).then().catch((error) => {
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error.response.data.error, "error");
      }
    });


  }, [currentGame]);

  if (!mountedComponent) return <div/>;

  console.log("RENDER <APP />");

  return (

    <AppContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        user: userInfo,
        theme: { themeName: theme, themeMode: themeMode, themeToggler: themeToggler },
        soundMode: { sound, setSound },
        currentGame,
        setCurrentGame
      }}
    >
      <ThemeProvider theme={themeMode}>
        <HelmetProvider>
          <Helmet>
            <html lang={t("meta.htmlLang")}/>
            <meta name="viewport"
                  content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no"/>
          </Helmet>
          <AliveScope>
            {authRouteRender}
          </AliveScope>
          <GlobalStyle/>
        </HelmetProvider>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
