import React from "react";
import { StyledHelpWrapper } from "./styledHelp";

const HelpContainer = () => {
  return (
    <StyledHelpWrapper>
      <span>!</span>
      <p>The site is in beta testing mode. There may be bugs and errors.</p>
    </StyledHelpWrapper>
  )
}

export default HelpContainer