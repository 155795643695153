import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useBetween } from "use-between";
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../logo/Logo";
import Navigation from "../navigation/Navigation";
import MobileNavigation from "../mobileNavigation/MobileNavigation";
import LeftSidebar from "../leftSidebar/LeftSidebar";
import AccountSettingsWrapper from "../../account/client/AccountSettingsWrapper";
import BurgerStates from "../mobileNavigation/BurgerStates";
import { StyledHeaderContainer, StyledHeaderWrapper, StyledMobileNav, StyledWrapper } from "./styledHeader";
import ListLanguage from "../listLanguage/ListLanguage";
// import {useLocation} from "react-router-dom";
import PaymentMethodContainer from "../../games/PaymentMethodContainer";
import { GAMES } from "../../games/Constants";
// import RoulettePaymentMethodContainer from "../../games/RoulettePaymentMethodContainer";
import { AppContext } from "../../../App";
import { generatePath } from "../../../utils/getLanguage";
import LeftSidebarChat from "../leftSidebar/Chat/LeftSidebarChat";
import { useLayout } from "../../../utils/useLayout";
import { StyledMenuHeader, StyledMenuHeaderItem } from "../../games/styledPaymentMethod";
import { BalanceNav } from "../mobileNavigation/BalanceNav";
// import RouletteStates from "../../games/roulette/RouletteStates";

const getGameNameFromPathName = (pathname) => {
  if (pathname === '/dice') {
    return GAMES.DICE;
  }
  if (pathname === '/balls') {
    return GAMES.BALLS;
  }
  return GAMES.ROULETTE;
}
// const useRouletteStates = () => useBetween(RouletteStates); // elint-disable-line;
const Header = ({ page }) => {
  // const path = useLocation();
  // const isRoulette = path.pathname.includes('roulette');
  const path = useLocation();
  const {
    submitData,
    setSubmitData,
    responseData,
    // rollPaymentMethodRef
  } = useBetween(page);
  const {
    isOpenBurger,
    isOpenCurrency,
    isOpenChat,
    setIsOpenChat,
    handleClearAll,
    isOpenProfile,
    setIsOpenProfile
  } = useBetween(BurgerStates);

  const { user, authenticated } = useContext(AppContext);

  const [menu, setMenu] = useState("");

  const headerRef = useRef(null);

  const { t } = useTranslation('siteOptions');
  const { isMobile } = useLayout();

  const PaymentMethodContainerRender = PaymentMethodContainer;

  useEffect(() => {
    if (isOpenBurger || isOpenCurrency || isOpenChat) {
      headerRef.current.parentElement.parentElement.style = 'overflow-y: hidden';
    } else {
      headerRef.current.parentElement.parentElement.style = 'overflow-y: auto';
    }
  }, [isOpenBurger, isOpenCurrency, isOpenChat]);

  const leftSidebar = useMemo(() => isMobile ? <LeftSidebar isNavigation={true}/> : null, [isMobile]);

  const paymentMethodContainerRender = useMemo(
    () => isMobile ? (
      <PaymentMethodContainerRender
        responseData={responseData}
        paymentMethod={submitData?.paymentMethod}
        setSubmitData={setSubmitData}
        isNavigation={true}
        game={getGameNameFromPathName(path.pathname)}
      />
    ) : null,
    [isMobile, responseData, submitData?.paymentMethod, setSubmitData, path.pathname],
  );

  const mobileNavigation = useMemo(() => isMobile ? <MobileNavigation/> : null, [isMobile]);
  const navigation = useMemo(() => !isMobile ? <Navigation/> : null, [isMobile]);
  const leftSidebarChat = useMemo(() => isMobile
    ? (
      <LeftSidebarChat
        setVisible={setIsOpenChat}
        setMenu={setMenu}
        visible={isOpenChat}
        isNavigation={true}
      />
    )
    : null, [isMobile, setIsOpenChat, setMenu, isOpenChat]);


  return (
    <StyledHeaderWrapper ref={headerRef}>
      <StyledHeaderContainer wrapper="content">
        <Logo/>
        {navigation}
        {mobileNavigation}
      </StyledHeaderContainer>
      <StyledMobileNav isOpen={isOpenBurger}>
        {leftSidebar}
        {
          authenticated ?
            (
              <StyledWrapper className={'account-nav'}>
                <h3>{t('hello')}, {user.nickname}</h3>
                <AccountSettingsWrapper
                  isNavigation={
                  <NavLink
                    to={generatePath(`/account/${user.nickname}`)}
                    onClick={handleClearAll}
                    className="link-profile">{t('myProfile')}
                  </NavLink>} />
              </StyledWrapper>
            ) : ""
        }
        <StyledWrapper>
          <h3>{t('menu')}</h3>
          <Navigation/>
        </StyledWrapper>
        <StyledWrapper>
          <h3>{t('language')}</h3>
          <ListLanguage/>
        </StyledWrapper>
      </StyledMobileNav>
      {leftSidebarChat}
      <StyledMobileNav isOpenCurrency={isOpenCurrency} fullscreen={true}>
        {paymentMethodContainerRender}
      </StyledMobileNav>
      {authenticated &&
        <>
          <BalanceNav/>
          <StyledMobileNav isOpen={isOpenProfile} fullscreen={true}>
            <StyledMenuHeader>
              <StyledMenuHeaderItem className={'nav-title'}>{t('hello')}, {user.nickname}</StyledMenuHeaderItem>
              <StyledMenuHeaderItem>
                <section
                  className="close"
                  onClick={() => setIsOpenProfile(false)}
                ></section>
              </StyledMenuHeaderItem>
            </StyledMenuHeader>
            <AccountSettingsWrapper
              isNavigation={
                <NavLink
                  to={generatePath(`/account/${user.nickname}`)}
                  onClick={handleClearAll}
                  className="link-profile">{t('myProfile')}
                </NavLink>}
            />
          </StyledMobileNav>
        </>
      }
    </StyledHeaderWrapper>
  );
};

export default Header;
