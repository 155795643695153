import styled from "styled-components";

export const StyledQrCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 20px 0;
  background-color: ${({ theme }) => theme.alertMessageBg};
  border-radius: 3px;
`;

export const StyledModalLoader = styled.div`
  min-height: ${({ minHeight }) => minHeight ?? '400px'};
  display: flex;
  align-items: center;
  justify-content: center;
`;